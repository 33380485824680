<template>
    <div>
        <v-text-field label="Card number" v-model="card_info.number" @input="changed" @focus="focus.number = true" @blur="focus.number = false"/>
        <v-layout row wrap>
            <v-flex xs4 style="padding: 0 12px">
                <v-text-field label="Exp month" v-model="card_info.expiration_month" @input="changed" @focus="focus.expiration_month = true" @blur="focus.expiration_month = false"/>
            </v-flex>
            <v-flex xs4 style="padding: 0 12px">
                <v-text-field label="Exp year" v-model="card_info.expiration_year" @input="changed" @focus="focus.expiration_year = true" @blur="focus.expiration_year = false"/>
            </v-flex>
            <v-flex xs4 style="padding: 0 12px">
                <v-text-field label="CVC/CVV" v-model="card_info.cvc" @input="changed" @focus="focus.cvc = true" @blur="focus.cvc = false"/>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      card_info: {
        number: '',
        expiration_month: '',
        expiration_year: '',
        cvc: '',
      },
      focus: {
        number: false,
        expiration_month: false,
        expiration_year: false,
        cvc: false,
      },
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.refresh();
      },
    },
    focus: {
      deep: true,
      handler() {
        this.refresh();
      },
    },
  },
  methods: {
    refresh() {
      if (!this.focus.number) {
        this.card_info.number = this.value.number;
      }
      if (!this.focus.expiration_month) {
        this.card_info.expiration_month = this.value.expiration_month ? this.value.expiration_month : '';
      }
      if (!this.focus.expiration_year) {
        this.card_info.expiration_year = this.value.expiration_year ? this.value.expiration_year : '';
      }
      if (!this.focus.cvc) {
        this.card_info.cvc = this.value.cvc;
      }
    },
    changed() {
      let y = parseInt(this.card_info.expiration_year, 10);
      if (y <= 99) y += 2000;
      this.$emit('input', {
        number: this.card_info.number,
        expiration_month: parseInt(this.card_info.expiration_month, 10),
        expiration_year: y,
        cvc: this.card_info.cvc,
      });
    },
  },
};
</script>
